@keyframes rightHand {
  from {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes leftHand {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-5deg);
  }
}

@keyframes rightHandContacts {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(20px);
  }
}

@keyframes logoPeachy {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes logoBlue {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes initialPeachy {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes initialBlue {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
