.main-container {
  margin-top: 0 !important;
  // background-image: url(app/assets/images/decorations/blob-scene-haikei.svg);

  .main-intro {
    @include mobile-card;

    .main-intro-img_container {
      width: 100vw;
      height: 400px;
      margin-bottom: 40px;
      .intro-img {
        height: 100%;
        width: 100%;
      }
    }

    .main-intro-img_container {
      width: auto;
      margin-bottom: 0;
    }

    .main-intro-h2_container {
      display: grid;
      grid-template-rows: 2fr;
      gap: 20px;

      h2 {
        @include h2;
      }

      a {
        @include button;

        &:active {
          box-shadow: inset 6px 10px 11px #02020288;
        }
      }
    }
  }
}
