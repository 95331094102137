@import "styles/helpers/variables.scss";
@import "styles/helpers/mixins.scss";
@import "styles/helpers/animations.scss";

@import "styles/components/homeSection";
@import "styles/components/recentProjectsSection.scss";
@import "styles/components/servicesSection.scss";
@import "styles/components/teamSection.scss";
@import "styles/components/contactsSection.scss";
@import "styles/components/headerComponents.scss";

* {
  box-sizing: border-box;
  outline-style: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  margin: 0;
  background-color: $backgound-color;

  #root {
    .main {
      padding: $mobile-spacing;
      padding-top: 0;
      background-image: url(app/assets/images/decorations/circle-scatter-haikei-mobile.svg);
      background-size: contain;

      .main-container {
        margin-top: 20px;
        overflow: hidden;
      }

      .main-contacts {
        margin-bottom: 20px;
      }

      section {
        margin-top: $mobile-section-spacing;
        position: relative;
        background-size: cover;

        .main-headline {
          z-index: -1;

          .headline {
            @include h1;
            color: white;
            font-size: 5rem;
          }
        }
        .decoration {
          z-index: -2;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          fill: $peachy-prime;
        }
      }
    }
  }
}

@import "styles/helpers/mediaQuery.scss";
