.header-section {
  z-index: 1000;
  position: sticky;
  top: 0;
  left: 0;

  .header-container {
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background-color: #fff;

    .header-logo {
      display: block;

      &.animate {
        .logo {
          polygon {
            &.cls-1,
            &.cls-2,
            &.cls-3 {
              transform-origin: center;
              animation-name: logoPeachy;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: ease-in-out;
            }

            &.cls-5,
            &.cls-6,
            &.cls-7,
            &.cls-4,
            &.cls-8 {
              transform-origin: center;
              animation-name: logoBlue;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: ease-in-out;
            }
          }
        }
      }

      .logo {
        display: block;
        width: 5em;

        &.initial {
          polygon {
            &.cls-1,
            &.cls-2,
            &.cls-3 {
              animation-name: initialPeachy;
              animation-duration: 2s;
              animation-timing-function: ease;
              animation-direction: alternate;
              transform-origin: center;
            }

            &.cls-5,
            &.cls-6,
            &.cls-7,
            &.cls-4,
            &.cls-8 {
              animation-name: initialBlue;
              animation-duration: 2s;
              animation-timing-function: ease;
              animation-direction: alternate;
              transform-origin: center;
            }
          }
        }

        .cls-1 {
          fill: $peachy-a;
        }
        .cls-2 {
          fill: $peachy-b;
        }
        .cls-3 {
          fill: $peachy-prime;
        }
        .cls-4 {
          fill: #231f20;
        }
        .cls-5 {
          fill: $blue-a;
        }
        .cls-6 {
          fill: $blue-b;
        }
        .cls-7 {
          fill: $blue-prime;
        }
        .cls-8 {
          fill: $blue-prime;
        }
      }

      a {
        display: block;
      }
    }

    .header-right-side {
      display: flex;
      gap: 20px;

      .header-contacts {
        display: none;
      }

      .header-hamburger-menu {
        @include button;

        .hamburger {
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          display: grid;
          grid-template-rows: auto;
          align-self: center;

          gap: 7px;

          &.is-active {
            transition-delay: 0.4s;
            transform: rotate(45deg);
          }

          div {
            width: 30px;
            height: 3px;
            background-color: #fff;
          }

          .bar1 {
            transition: all 0.1s ease-in-out;
            transition-delay: 0.2s;

            &.change {
              -webkit-transform: translate(0px, 10px);
              transform: translate(0, 10px);
            }
          }

          .bar2 {
            width: 100%;
            transition: all 0.1s ease-in-out;
            transition-delay: 0.1s;

            &.change {
              width: 0;
            }
          }

          .bar3 {
            -webkit-transform: rotate(0deg) translate(0px, 0px);
            transform: rotate(0deg) translate(0px, 0px);
            transition: all 0.1s ease-in-out;
            transition-delay: 0.2s;

            &.change {
              -webkit-transform: rotate(90deg) translate(-10px, -0px);
              transform: rotate(90deg) translate(-10px, 0px);
            }
          }
        }
      }
    }
  }

  &.open {
    .header-hamburger-menu {
      box-shadow: inset 5px 5px 12px 0px #02020299 !important;
    }
    .side-menu {
      transform: translateX(0);
    }
  }

  .side-menu {
    position: fixed;

    transition: all 0.5s ease;
    top: 78px;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    height: calc(100vh - 78px);

    display: grid;
    grid-template-rows: repeat(1fr, 5);
    place-items: center;

    :nth-child(1) {
      background-color: #ff5465;
    }
    :nth-child(2) {
      background-color: #f15060;
    }
    :nth-child(3) {
      background-color: #d94856;
    }
    :nth-child(4) {
      background-color: #b33b47;
    }
    :nth-child(5) {
      background-color: #73262e;
    }

    .side-menu_items {
      @include h1;
      font-size: 6vw;
      text-decoration: none;
      color: #fff;
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
    }
  }
}
