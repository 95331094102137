.main-contacts {
  .main-headline {
  }

  .decoration {
    fill: $blue-prime !important;
  }

  .contacts-container {
    @include mobile-card;
    h2 {
      @include h2;
      font-size: 2rem;
      text-align: center;

      hr {
        border-color: #fff;
      }
    }

    .img {
      position: absolute;
      right: 0;
      display: none;
    }

    .contacts {
      display: grid;
      grid-template-rows: auto;
      align-content: center;
      gap: 10px;
      margin-top: 40px;

      .contact-phone,
      .contact-email {
        width: 100%;
        display: grid;
        align-content: center;

        grid-template-columns: 1fr 50px;
        gap: 10px;

        a {
          @include button;

          display: grid;
          grid-template-columns: 20px 1fr;
          justify-content: center;
          align-items: center;

          font-size: 1rem;

          svg {
            justify-self: center;
          }
        }

        .copy-icon {
          width: 100%;
          @include button;
          font-size: 3rem;
        }
      }

      .contact-social {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        a {
          @include button;
        }
      }
    }
  }
}
