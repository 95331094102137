.main-team {
  .main-headline {
  }

  .team-container {
    @include mobile-card;

    .slick-slider {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 2fr 50px;

      .slick-arrow {
        grid-row: 2;
        position: relative;
        align-self: self-end;
        justify-self: center;
        color: white;
        padding: 10px;
        font-size: 40px;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: $peachy-prime;
        top: 0;
        z-index: 1000;
        transition: all 0.3s ease;
        box-shadow: 7px 7px 14px 0px #02020299;

        &:active {
          box-shadow: inset 6px 10px 11px #02020288;
        }

        &.slick-next {
          right: 0px;
          transform: none;
        }

        &.slick-prev {
          left: 0px;
          transform: none;
        }
      }

      .slick-list {
        grid-row: 1;
        grid-column: 1 / span 2;

        
        .team-member {
          overflow: hidden;

          padding: 10px;

          img {
            width: 100%;
            margin-bottom: 20px;

            border-radius: 50%
          }

          .member-description {
            text-align: center;
            h2 {
              @include h2;
              font-size: 2rem;
              margin-bottom: 20px;

            }

            p {
              @include h2;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
