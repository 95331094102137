@media screen and (max-width: $phones-small) {
  body {
    #root {
      .main {
        section {
          .main-headline {
            .headline {
              font-size: 4rem;
            }
          }
        }

        .main-contacts {
          .contacts-container {
            .contact-element {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-width) {
  body {
    width: 100vw;
    margin: 0;
    background-color: $backgound-color;

    #root {
      .main {
        // overflow-x: hidden;
        padding: 20px;

        section {
          margin-top: $mobile-section-spacing;
          // overflow: hidden;
          position: relative;

          .main-headline {
            z-index: -1;

            .headline {
              @include h1;
              color: white;
              font-size: 5rem;
              padding-left: $tablet-spacing;
            }
          }
          .decoration {
            z-index: -2;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            fill: $peachy-prime;
          }
        }

        .main-container {
          margin-top: 20px;
          overflow: hidden;
          background-image: url(../../app/assets/images/decorations/blob-scene-haikei.svg);

          .main-intro {
            padding: $tablet-spacing;

            .main-intro-img_container {
              z-index: -1;
              width: 100%;
              grid-column: 1;
            }

            .main-intro-h2_container {
              grid-column: 2;

              h2 {
                font-size: $tablet-font-size;
              }
            }
          }
        }

        .main-recent_projects {
          background-image: url(../../app/assets/images/decorations/circle-scatter-haikei.svg);

          .recent_projects-container {
            padding: $tablet-spacing;

            .slide-comment {
              p {
                font-size: $tablet-font-size;
              }
            }
          }
        }

        .main-services {
          background-image: url(../../app/assets/images/decorations/layered-waves-haikei.svg);

          .services-container {
            .card {
              padding: $tablet-spacing;

              .card-content {
                display: grid;
                grid-template-columns: 1fr 1fr;
                place-items: center;

                .img {
                  grid-column: 1;
                }

                .content-description {
                  grid-column: 2;

                  h3 {
                    font-size: 1.5rem;
                  }

                  p {
                    font-size: 1.2rem;
                  }

                  h3,
                  p {
                    margin-left: 0;
                    text-align: left;
                  }
                }
              }

              .contact-button {
                max-width: 400px;
                margin: auto;
              }
            }
          }
        }

        .main-team {
          background-image: url(../../app/assets/images/decorations/blob-scatter-haikei.svg);

          .team-container {
            padding: $tablet-spacing;

            .team-member {
              display: grid !important;
              grid-template-columns: 1fr 1fr;
              place-items: center;

              .member-img {
                grid-column: 1;
              }

              .member-description {
                grid-column: 2;
              }
            }
          }
        }

        .main-contacts {
          margin-bottom: 20px;
          background-image: url(../../app/assets/images/decorations/wave-haikei.svg);

          .contacts-container {
            padding: $tablet-spacing;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) and (max-height: 800px) and (min-width: 1024px) {
  html {
    body {
      #root {
        .main {
          .main-recent_projects {
            .recent_projects-container {
              .slide-content {
                .slide-img_comment {
                  display: flex;
                  flex-direction: row;

                  img {
                    max-width: 500px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-width) {
  html {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      border-radius: 10px;
      background-color: #e7000000;
    }

    &::-webkit-scrollbar {
      width: 12px;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: gray;
    }

    body {
      position: relative;
      min-height: 100%;
      width: 100vw;
      overflow-y: hidden;
      margin: auto;
      transition: all 0.4s ease-in-out;

      #root {
        .header-section {
          position: fixed;
          left: 0;
          right: 0;

          .header-container {
            .header-right-side {
              .header-contacts {
                display: block;
                @include button;
                color: #fff;
                font-weight: 600;
                border-radius: 5px;
                box-shadow: 5px 5px 7px #d9d9d9, -5px -5px 7px 5px #faf9f9;

                &.active {
                  box-shadow: inset 5px 5px 7px 0px #02020299;
                  background-color: $blue-prime;
                }

                svg {
                  margin-right: 10px;
                }
              }

              .header-hamburger-menu {
                display: none;
              }
            }
          }
        }

        .main {
          width: 500vw;
          display: grid;
          grid-template-columns: repeat(5, 100vw);
          height: 100vh;
          padding: 0;

          section {
            overflow: hidden;
            height: calc(100% - 78px);
            width: 100%;

            padding: 40px;
            margin-top: 78px !important;

            perspective: 2000px;
            position: relative;

            .content-wrapper {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              max-width: 1400px;
              margin: auto;
            }

            .main-headline {
              z-index: -1;

              .headline {
                @include h1;
                color: white;
                font-size: 5rem;
                padding-left: 0;
                margin: auto 0;
              }
            }
            .decoration {
              width: 98%;
              height: 98%;
            }
          }

          .main-container {
            grid-column: 1;

            .main-intro {
              @include paddingAndHeight;
              display: grid;
              grid-template-columns: 2fr 1fr;
              place-items: center;
              @include parentPopOut;

              .main-intro-img_container {
                z-index: -1;
                width: 100%;
                grid-column: 1;
                height: 100%;
                transform: none;

                svg {
                  @include transition;
                }
              }

              .main-intro-h2_container {
                grid-column: 2;
                @include transition;
                h2 {
                  font-size: 1.5rem;
                }
              }

              &.popOut {
                transition: none;

                .main-intro-img_container {
                  svg {
                    @include popOut;

                    #hand_l {
                      animation: infinite linear leftHand 0.5s alternate;
                      transform-origin: right;
                      transform-box: fill-box;
                    }

                    #hand_r {
                      animation: infinite linear rightHand 0.5s alternate;
                      transform-origin: right;
                      transform-box: fill-box;
                    }
                  }
                }

                .main-intro-h2_container {
                  @include popOut;

                  a {
                    &:active {
                      box-shadow: inset 6px 10px 11px #02020288;
                    }
                    @include box-shadow;
                  }
                }
              }
            }
          }

          .main-recent_projects {
            grid-column: 2;
            .recent_projects-container {
              @include paddingAndHeight;
              display: flex;
              flex-direction: column;
              justify-content: center;

              @include parentPopOut;

              &.popOut {
                .slick-slider {
                  @include popOut;

                  .slick-slide {
                  }

                  .slide-content {
                    .slide-visit {
                      box-shadow: 7px 7px 14px 0px #25252560 !important;
                    }
                  }

                  svg {
                    @include popOut;
                    @include box-shadow;

                    &:hover {
                      background-color: $blue-prime;
                    }

                    &:active {
                      box-shadow: inset 6px 10px 11px #02020288;
                    }
                  }
                }
              }

              .slick-slider {
                height: 100%;
                @include transition;
                gap: 10px;
                grid-template-columns: 50px 1fr 50px;
                grid-template-rows: none;

                .svg {
                  @include transition;
                }

                .slick-arrow {
                  box-shadow: none;
                  height: 50px;
                  align-self: center;
                  grid-row: 1;
                }

                .slick-prev {
                  grid-column: 1;
                }

                .slick-next {
                  grid-column: 3;
                }

                .slick-list {
                  grid-column: 2;
                  grid-row: 1;

                  .slick-track {
                    height: 100%;
                  }

                  .slick-slide {
                    // padding: 30px 40px;

                    display: grid;
                    place-items: center;

                    .slide-content {
                      align-self: center;
                      flex-direction: column;
                      padding: 20px;
                      border: 2px solid #fff;
                      // background-color: $blue-prime;

                      .slide-headline {
                        width: 100%;
                        height: auto !important;
                        margin-top: 10px;
                        margin-bottom: 20px;
                      }

                      .slide-img_comment {
                        height: auto !important;
                        margin: auto;

                        img {
                          max-width: 700px;
                          margin: auto;
                        }

                        .comment {
                          height: auto;
                          margin: auto;
                          margin-left: 10px;
                          p {
                            text-align: center;
                            font-size: 1rem;
                          }

                          .slide-visit {
                            margin: auto;
                            max-width: 300px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .main-services {
            grid-column: 3;
            overflow: auto;

            .content-wrapper {
              height: auto;
            }

            .main-headline {
              align-self: flex-start;
              position: sticky;
              top: 38%;
            }

            .decoration {
              width: 70%;
            }

            &::-webkit-scrollbar-track {
              border-radius: 10px;
            }

            &::-webkit-scrollbar {
              width: 12px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $blue-prime;
              border-radius: 10px;
            }

            .services-container {
              align-self: start;

              .content-img {
                min-width: 400px;
              }

              .card {
                backdrop-filter: none;
                &.popOut {
                  .card {
                    @include transition;
                    @include parentPopOut;

                    h2,
                    .card-content,
                    .contact-button {
                      @include popOut;
                    }
                  }

                  .contact-button {
                    @include box-shadow;
                  }
                }
              }

              .contact-button {
                &:active {
                  box-shadow: inset 6px 10px 11px #02020288;
                }
              }
            }
          }

          .main-team {
            grid-column: 4;

            .team-container {
              @include paddingAndHeight;
              display: flex;
              flex-direction: column;
              justify-content: center;

              @include parentPopOut;

              &.popOut {
                .slick-slider {
                  @include popOut;

                  svg {
                    @include popOut;
                    @include box-shadow;

                    &:hover {
                      background-color: $blue-prime;
                    }

                    &:active {
                      box-shadow: inset 6px 10px 11px #02020288;
                    }
                  }
                }
              }

              .slick-slider {
                height: 100%;
                @include transition;
                gap: 10px;
                grid-template-columns: 50px 1fr 50px;
                grid-template-rows: 0;

                .svg {
                  @include transition;
                }

                .slick-arrow {
                  box-shadow: none;
                  height: 50px;
                  align-self: center;
                }

                .slick-prev {
                  grid-column: 1;
                }

                .slick-next {
                  grid-column: 3;
                }

                .slick-list {
                  grid-column: 2;
                  grid-row: 2;

                  .slick-track {
                    height: 100%;
                  }

                  .slick-slide {
                    padding: 10px;
                    display: grid;
                    place-items: center;

                    .team-member {
                      display: grid !important;
                      place-items: center;
                      border-radius: 5px;
                      grid-template-rows: 1fr 1fr;
                      gap: 20px;
                      border: 2px solid #fff;
                      // background-color: $blue-prime;

                      .member-img {
                        grid-row: 1 / span 2;

                        img {
                          height: 100%;
                          margin: 0;
                        }
                      }

                      .member-description {
                        grid-row: 1 / span 2;
                        height: auto;
                        text-align: center;

                        p {
                          font-size: 1rem;
                        }
                      }
                    }
                  }
                }
              }

              .team-member {
                gap: 20px;
              }
            }
          }

          .main-contacts {
            grid-column: 5;

            .contacts-container {
              overflow: hidden;
              @include paddingAndHeight;
              width: 100%;

              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr 2fr 1fr;
              @include parentPopOut;

              &.popOut {
                h2 {
                  @include popOut;
                }

                .contact-element,
                .copy-icon,
                .contact-element-social {
                  @include popOut;
                  @include box-shadow;
                }

                #boy {
                  #hand_reight {
                    animation: infinite ease-in-out rightHandContacts 1s
                      alternate;
                    transform-origin: center;
                    transform-box: fill-box;
                  }
                }
              }

              h2 {
                @include transition;
                grid-row: 1;
                grid-column: 1;
                align-self: end;
              }

              .img {
                grid-column: 2;
                grid-row: 1 / span 3;
                display: block;
                height: 100%;
                position: relative;
              }

              .contacts {
                align-self: center;
                grid-column: 1;
                grid-row: 2;
                gap: 20px;

                .contact-element {
                  font-size: 1rem;
                }

                .contact-element,
                .copy-icon,
                .contact-element-social {
                  &:active {
                    box-shadow: inset 6px 10px 11px #02020288;
                  }
                }

                .contact-phone,
                .contact-email {
                  gap: 20px;
                }

                .contact-social {
                  gap: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
