.main-services {
  .main-headline {
    .headline {
    }
  }
  .decoration {
    fill: $blue-prime !important;
  }

  .services-container {
    .card {
      //card
      @include mobile-card;
      margin-bottom: 50px;

      &:nth-last-of-type() {
        margin-bottom: 0px;
      }

      h2 {
        @include h2;
        text-align: center;
        font-size: 2rem;
      }

      .card-content {
        //card-content
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .content-img {
          // content-img
          svg {
            height: 100%;
            width: 100%;
          }
        }

        .content-description {
          //conted-description
          h3 {
            @include h2;
            margin: 10px;
            text-align: center;
            font-size: 1.5rem;
          }

          p {
            @include h2;
            text-align: center;
            font-size: 1rem;
          }
        }
      }

      .contact-button {
        //contact button
        @include button;
      }
    }
  }
}
