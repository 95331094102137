@import url("https://fonts.googleapis.com/css2?family=Monda&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

@mixin mobile-card() {
  padding: $mobile-spacing;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 5px;
}

@mixin button() {
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  background-color: $peachy-prime;
  border-radius: 5px;
  font-family: "Monda", sans-serif;
  font-size: 1rem;
  color: #fff;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: $desktop-width) {
    box-shadow: 7px 7px 14px 0px #02020299;
  }

  &:active {
    box-shadow: inset 5px 5px 12px 0px #02020299;
    transition: all 0.3s ease-in-out;
    // box-shadow: none;
    // transform: scale(0.99);
  }

  @media screen and (min-width: $desktop-width) {
    &:hover {
      background-color: $blue-prime;
    }
  }
}

// //3D effect
@mixin parentPopOut {
  transform-style: preserve-3d;
  perspective: 1000;
}

@mixin popOut() {
  transform: translateZ($popUpZ);
  transition: all 0.3s ease-in-out;
}

@mixin transition() {
  transition: all 0.3s ease-in-out;
}

@mixin h1() {
  font-family: $title-font;
  letter-spacing: 4px;
  margin: 0;
  font-weight: 50;
}

@mixin paddingAndHeight {
  height: 100%;
  padding: $tablet-spacing;
  // max-width: 1300px;
  // margin-left: auto;
  backdrop-filter: none;

  @media screen and (min-width: 1550px) {
    padding: 25px;
  }
}

@mixin h2() {
  font-family: "Raleway", sans-serif;
  margin: 0;
  color: #fff;
}

@mixin button-shadow() {
  -webkit-box-shadow: 2px 21px 16px -6px rgba(5, 5, 5, 0.4);
  -moz-box-shadow: 2px 21px 16px -6px rgba(5, 5, 5, 0.4);
  box-shadow: 2px 21px 16px -6px rgba(5, 5, 5, 0.4);
}

@mixin box-shadow() {
  box-shadow: 13px 13px 20px 3px rgba(0, 0, 0, 0.281);
}
