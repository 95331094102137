.main-recent_projects {
  .recent_projects-container {
    @include mobile-card;

    .slick-slider {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 2fr 50px;

      .slick-arrow {
        @include button;
        grid-row: 2;
        position: relative;

        height: 100%;
        width: 100%;
        top: 0;

        z-index: 1000;
        transition: all 0.3s ease;
        box-shadow: 7px 7px 14px 0px #02020299;

        &:active {
          box-shadow: inset 6px 10px 11px #02020288;
        }

        &.slick-next {
          right: 0px;
          transform: none;
        }

        &.slick-prev {
          left: 0px;
          transform: none;
        }
      }

      .slick-list {
        grid-row: 1;
        grid-column: 1 / span 2;

        .slick-slide {
          padding: 10px;

          .slide-content {
            overflow: hidden;
            padding: 10px;
            border-radius: 5px;

            .slide-headline {
              h1 {
                @include h2;
                text-align: center;
                font-size: 2rem;
              }

              hr {
                border-color: #fff;
              }
            }

            .slide-img_comment {
              width: 100%;

              img {
                width: 100%;
                margin-bottom: 20px;
                border-radius: 5px;
              }

              .comment {
                p {
                  @include h2;
                  font-size: 1.5rem;
                  margin: 20px 0;
                }

                .slide-visit {
                  @include button;
                  box-shadow: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
