@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

// resolutions

$desktop-width: 1025px;

$tablet-width: 800px;

$phones-small: 370px;

// fonts

$title-font: "Righteous", cursive;

// font sizes

$tablet-font-size: 2rem;
$mobile-content: 3.5vw;

//spacing
$mobile-spacing: 20px 10px;
$mobile-section-spacing: 70px;

$tablet-spacing: 20px;

//colors

$peachy-prime: #f15060;
$peachy-a: #b33b47;
$peachy-b: #73262e;

$blue-prime: #3d6ef7;
$blue-a: #2e53b8;
$blue-b: #1e3678;

$backgound-color: #1e2a42;
$gray: #535353;

$maxWidth: 1400px;
$popUpZ: 20px;
